import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <section>
      <div className="container">
        <h2>General</h2>
        <br />
        <p>
          When access to Followers Hub’s services, you agree to the terms listed
          below and if you disagree to any of the terms then you may not access
          to the services. We reserve the right to change this policy at any
          time without notice, so please check it regularly. Please read the
          terms and conditions very carefully before using Followers Hub We
          strongly recommend that you explore the Followers Hub website before
          using the service.
        </p>
        <p>
          This is the most recent terms of services agreements as of Jun 2021.
        </p>
        <br />
        <br />
        <h2>Terms of Use</h2>
        <br />
        <p>
          Followers Hub is not affiliated with Instagram, any other social media
          applications or third party sites.
        </p>
        <p>
          We are not responsible for any of your actions or consequences when
          using Followers Hub.
        </p>
        <p>
          When using Followers Hub, be aware that we do not control nor can we
          predict the user behaviors such as liking, following or commenting on
          your images or gallery.
        </p>
        <p>
          Followers Hub is an entertainment service and we do not guarantee to
          give you 100% real reports. It was only designed to make you having
          fun. You accept that we do not guarantee to give you exact true
          reports by downloading and upgrading your membership.
        </p>
        <p>
          The content of the pages of this website is for your general
          information and use only. It is subject to change without notice.
        </p>
        <p>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on this website for
          any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.
        </p>
        <p>
          Never upload Adult contents or any other inappropriate contents into
          these service rotations which are not allowed on Instagram.
        </p>
        <p>
          You are free and solely responsible for the hashtags you create and
          for your own use.
        </p>
        <p>
          The time of voluntary inactivity - if you choose to stop the script
          for a specific period - shall not be compensated. We ensure our fast
          update of the data, but in any case if late update happens we have the
          right not to compensate you. You should never knowingly exploit the
          system.
        </p>
        <br />
        <br />
        <h2 id="pp">Privacy Policy</h2>
        <br />
        <p>
          Followers Hub does not share your credential with a third party,
          therefore, you need to use your login credentials to login to your
          applications and avail the services. Followers Hub will neither ask
          for your password nor use your credentials without your permission.
        </p>
        <p>
          Please note that if you change your password, the script you set will
          automatically stop. To activate it again, you should re-launch the app
          and re-login with the new password. During such an event, the inactive
          period shall not be considered or added to your license.
        </p>
        <p>
          Followers Hub’s system servers are secured using high level encryption
          to prevent unauthorized access of your account. However, in case of
          security breach and loss of data, we are not responsible for the
          consequences that follow the event.
        </p>
        <p>
          We reserve the right to modify this privacy policy at any time, so
          please review it frequently. Changes and clarifications will take
          effect immediately upon their posting on the website or app. If we
          make material changes to this policy, we will notify you here that it
          has been updated, so that you are aware of what information we
          collect, how we use it, and under what circumstances, if any, we use
          and/or disclose it. If our website or app are acquired or merged with
          another company, your information may be transferred to the new
          owners.
        </p>
      </div>
    </section>
    <Footer />
  </Layout>
);

export default IndexPage;
